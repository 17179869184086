import React from 'react';
import Layout from '../components/layout';
import Footer2 from '../components/footer2';
import "../styles/aboutStyles.scss";
import blueWing from '../assets/img/blue-wing.svg'
import greenWing from '../assets/img/green-wing.svg'
import "../styles/tailwind.css";
import { Helmet } from 'react-helmet';
import { motion, AnimatePresence } from 'framer-motion';


const About = props => {
    return <Layout>
        <Helmet>
        <title>Next.Legal | About </title>
                <meta name="description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="keywords" content="legal tech, startup, law" />
                <meta
                  name="robots"
                  content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
                />
                <link rel="canonical" href="https://yourapp.com" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Next.Legal | About" />
                <meta property="og:description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta property="og:url" content="https://next-legal.io" />
                <meta property="og:site_name" content="Next.Legal" />
                <meta property="og:image" content="../assets/img/icon-256x256.png" />
                <meta property="og:image:secure_url" content="https://next-legal.io" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />
                <meta name="twitter:card" content="../assets/img/icon-256x256.png" />
                <meta name="twitter:description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="twitter:title" content="Next.Legal | About" />
                <meta name="twitter:image" content="../assets/img/icon-256x256.png" />
        </Helmet>
        <div className="about-container">
        <AnimatePresence>
        <div className="mx-auto about-content">
            <motion.div 
            className="lex-wings-about">
                    <motion.img 
                            animate={{ y: 0, opacity: 1 }}
                            initial={{ y: 100, opacity: 0 }}
                            transition={{
                              duration: 1,
                              ease: [0.6, 0.05, -0.01, 0.9],
                              delay: 0.4
                            }}
                    className="blueWing" src={blueWing} alt="bytelex blue wing"/>
                    <motion.img 
                      animate={{ y: 0, opacity: 1 }}
                      initial={{ y: 100, opacity: 0 }}
                      transition={{
                        duration: 1,
                        ease: [0.6, 0.05, -0.01, 0.9],
                      }}
                    className="greenWing" 
                    src={greenWing} 
                    alt="bytelex green wing"/>
            </motion.div>
            <motion.div 
                  animate={{ y: 0, opacity: 1 }}
                  initial={{ y: 100, opacity: 0 }}
                  transition={{
                    duration: 1,
                    ease: [0.6, 0.05, -0.01, 0.9],
                  }}
            className="about-title">
                <h2 className="about-heading">Building  a thriving startup ecosystem.</h2>
                <p className="about-para p1">Next.Legal brings together company founders and entrepreneurs, legal technology enthusiasts and practitioners and leading African venture funders, leading start-ups and it aims to connect investment between Africa and the rest of the world.</p>
                <p className="about-para p2">Now running for its second year, the Next.Legal Summit is a legal event organised by Bytelex Advocates, East Africa’s premier law practice, to celebrate, showcase and discuss emerging legal trends, legal technology, startup law and financing innovation in the start-up ecosystem in Africa. Attendees can expect hours of learning and engaging, with sessions on startup law, legal tech advancements, innovations in finance, cybersecurity compliance while staying true to Bytelex’ vision of Building Radical Ideas.</p>
            </motion.div>
        </div>
        </AnimatePresence>
        <Footer2/>
        </div>
    </Layout>
}

export default About
